.blue-color {
    color: #00005C !important;
}
.blue-background {
    background-color: #00005C !important;
}
.text-color {
    color: #627193 !important;
}
.pink-border {
    border-bottom: 6px solid #FF5670 !important;
}
.yellow-color {
    border-bottom: 6px solid #FDB400 !important;
}
.pattner-background {
    background:linear-gradient(101.06deg, #fcfffe 0.46%, #b9e0eb 100%);
}
.light-green {
    color: #90EE90;
}
.green-background {
    background-color: #90EE90;
}
/* Navbar Style */
.nav-logo {
    font-size: 38px;
    font-weight: 500;
}
.nav-linktext {
    font-size: 18px;
    font-weight: 500;
    color: #627193;
}
.borderb:hover {
    font-size: 18px;
    font-weight: 700;
    color: #00005C;
    border-bottom: 3px solid #00005C;
}
.navbar-button {
    border-radius: 25px;
    margin-top: -2px;
    padding: 8px 20px;
}
/* Home Page */
.hometop-heading {
    font-size: 50px;
    font-weight: 700;
    color: #0C0C86;
    padding-top: 65px;
}
.light-bl {
    color: #0C0C86;
}
.hometop-text {
    font-size: 19px;
    font-weight: 400;
    color: #505F81;
}
.color-contact{
    color: 
    #505F81;
    font-size: 17px;
}
.cardText {
    font-size: 22px;
    font-weight: 400;
}
.all-btn{
    font-size: 18px;
    font-weight: 700;
    line-height: 16px;
    background-color: #90EE90 !important;
    color: #0C0C86 !important;
    border-style: none;
    padding: 20px 36px;
    border-radius: 36px;
}
.all-btn:hover{
background-color: white;
}
.homeCard {
    box-sizing: border-box;
    display: flex;
    border-radius: 22px;
    border: 4px solid rgba(255, 255, 255, 0.9);
    box-shadow: 0px 28px 75px rgba(12, 12, 134, 0.13);
    backdrop-filter: blur(350px);
    color: #0C0C86;
    background: linear-gradient(135deg, #f1fffc 0%, #fdedff 87.5%);
}
.border-rad{
    border-radius: 22px;
}
.blue-obrder{
    border-bottom: 6px solid #00005C;
}
.time-icon{
    font-size: 25px;
    font-weight: 700;
    color: #0C0C86;
}
.time-text{
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}
.cardTop-text{
    font-size: 45px;
    font-weight: 700;
    text-align: center;
    color: #0C0C86;
}
.cardtop-textine{
    font-size: 26px;
    font-weight: 400;
    text-align: center;
    color:   #505F81;
}
.frame-section{
    /* background-color: #0C0C86; */
    /* background: linear-gradient(135deg, #2AF4D0 0%, #A92EBE 87.5%); */
    /* background: linear-gradient(135deg, #00b191 0%, #742ebe 87.5%); 
 opacity: 9; */
/* background-image: url(/src/View/Assets/Images/asaan.jpg);
background-size: cover;
background-repeat: no-repeat;
height: 100vh; */
/* background-color: #138EF8; */
}
.frame-text{
    font-size: 45px;
    font-weight: 800;
    text-align: center;
    color: #ffffff;
    margin-top: 30px;
  
}
.rahe{
    position: relative;
    top: -100;
    /* padding-top: 20px; */
}
.frame-textt{
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #ffffff;
}
.frameimg{
    /* margin-top: -120px; */
    z-index: -1;
    margin-top: -175px;
}
.new-frame-text{
    /* position: relative;
    top: 172px; */
}


/* Pattner */
.patner-heading{
    font-size: 40px;
    font-weight: 800;
    text-align: center;
    color: #0C0C86;
}
.patner-section{
    background-image: url(../Images/starrback.png);
    background-size: cover;
    background-repeat: no-repeat;
   
}

/* Contact US */
.contact-heading{
    font-weight: 700;
    font-size: 40px;
    text-align: center;
    color: #0C0C86;
}
.contact-background{
    background-color:  #F6F6FA;
}
.textarea-sy{
    height: 30vh !important;
}

/* contact page */
.contact-headingtio{
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    color: #0C0C86;
}
.contact-paragset{
    font-size: 20px;
    font-weight: 500;
  color: #0C0C86;
}
.contact-margin{
    margin-top: -100px;
}
.contact-marginl{
    margin-top: -30px;
}
.login-heading{
    font-size: 40px;
    font-weight: 700;
    color: #0C0C86;
}
.heading-paragrah{
    font-size: 20px;
    font-weight: 400;
    color: #505F81;
}
.login-forgot{
    display: block;
    text-decoration: none;
    text-align: right;
    color: #0C0C86 !important;
    font-weight: 500;
}
.login-card{
    border-radius: 36px !important;
    background: rgba(255, 255, 255, 0.67) !important;
}
.login-lin{
    color: #90EE90;
}
/* Footer Style */
.footer-heading {
    font-size: 46px;
    font-weight: 500;
    line-height: 62.1px;
    color: #00005C;
}
.fotter-btn {
    font-size: 18px;
    font-weight: 700;
    line-height: 16px;
    background-color: #90EE90;
    border-style: none;
    padding: 20px 36px;
    border-radius: 36px;
}
.foterarrow-btn {
    width: 13px;
}
.foter-border {
    border-bottom: 1px solid #EAE5E5;
}
.foterHeading-one {
    font-size: 36px;
    font-weight: 400;
    color: #0C0C86;
    line-height: 44px;
}
.foterContact-text {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    color: #627193;
}
.foterContact-number {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    color: #0C0C86;
}
.footer-twiter {
    width: 37px;
    height: 30px;
}
.footerTwiter-text {
    font-size: 16px;
    font-weight: 400;
    color: #0C0C86;
    text-decoration: none;
}
.fotterCompany-text {
    font-size: 16px;
    font-weight: 700;
    color: #0C0C86;
}
.fotter-unorder {
    list-style-type: none;
    padding: 0;
}
.footer-anker {
    text-decoration: none;
    font-size: 18px;
    font-weight: 400;
    color: #505F81;
    display: block;
    padding: 5px;
}
/* Header */
.hom-btn{
    border-right: 2px solid #505F81;
    padding-right: 10px;
    color: #505F81;
    text-decoration: none;
}
.btn-homi{
    color: #90EE90;
    text-decoration: none;
}
.fotter-btn-header:hover{
    background-color: white;
    color: black !important;
}
.fotter-btn-header{
    font-size: 18px;
    font-weight: 700;
    line-height: 16px;
    background-color: white;
    border-style: none;
    padding: 15px 36px;
    border-radius: 36px;
    outline: 1px solid #627193;
}
.header-textstyle{
    font-size: 50px;
    font-weight: 700;
    color: #0C0C86;
}
.about-headingtop{
    font-size: 24px;
    font-weight: 700;
    color: #0C0C86;
}
.about-textpar{
    font-size: 18px;
    font-weight: 400;
    color: #505F81;
}
.about-section{
    background: linear-gradient(135deg, #fbfffe 0%, #fef6ff 87.5%);
}
.one{
    height: 30px;
    width: 30px;
    background-color: #90EE90;
    text-align: center;
    border-radius: 50%;
    font-size: 18px;
    padding-top: 4px;
    font-weight: 600;
}
.collapseOnee{
border-radius: 20px !important;
}

.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 1.25rem;
    font-size: 19px;
    font-weight: 700;
    color: #0C0C86;
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 20px !important;
    overflow-anchor: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
}
.accordion-item {
    background-color: #fff;
    border-radius: 20px !important;
}
.accordion-button:not(.collapsed) {
    color: #0c63e4;
    background-color: #ffffff !important;
    box-shadow: none !important;
}
.accordion-button:focus {
    z-index: 3;
    border-color: white !important;
    outline: none !important;
    box-shadow: none !important;
    border-top: 5px solid #0C0C86 !important;
}
.head-paratexr{
    font-size: 18px;
    font-weight: 500;
    color: #505F81;
}
/* PRIVACY POLICY */
.privacy-heading{
    font-size: 24px;
    font-weight: 700;
    color: #0C0C86;
}
.privicy-paragrah{
    font-size: 18px;
    font-weight: 400;
    color: #505F81;
    padding: 10px 0;
    text-align: justify;
}
.register-paragrah {
    font-size: 18px;
    font-weight: 400;
    color: #505F81;
    margin-top: -20px !important;
}
/* .register-border{
border-radius: 8px !important;
} */



/* RESPONSIVE  */

@media screen and (max-width: 768px){
    .nav-link {
        display: inline-block;
        padding: 0.5rem 1rem;
        color: #0d6efd;
        text-decoration: none;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
    }
    .hometop-heading {
        font-size: 35px;
        font-weight: 700;
        color: #0C0C86;
        /* padding-top: -102px !important; */
        margin-top: -50px;
    }
    .hometop-text {
        font-size: 15px;
        font-weight: 400;
        color: #505F81;
    }
    .contact-margin {
        margin-top: -65px;
    }
    .frame-text {
        font-size: 27px;
        font-weight: 800;
        text-align: center;
        color: #ffffff;
        /* margin-top: 30px; */
    }
    .formtext-new{
        position: relative;
        top: -56px;
    }
    .frame-textt {
        font-weight: 400;
        font-size: 17px;
        text-align: center;
        color: #ffffff;
        position: relative;
        top: -74px;
    }
   
}

@media screen and (max-width: 425px){
    .cardTop-text {
        font-size: 32px;
        font-weight: 800;
        text-align: center;
        color: #0C0C86;
    }
    .cardtop-textine {
        font-size: 17px;
        font-weight: 400;
        text-align: center;
        color: #505F81;
    }
    .cards-pad{
        padding-top: 20px;
    }
    .farm-bot{
        padding-bottom: 50px;
    }
    
    .frame-text {
        font-size: 19px;
        font-weight: 800;
        text-align: center;
        color: #ffffff;
        margin-top: 30px;
    }
    .frame-textt {
        font-weight: 400;
        font-size: 11px;
        text-align: center;
        color: #ffffff;
        margin-top: -17px;
        position: relative;
        top: -63px;
}
}

@media screen and (max-width: 425px){
    .frame-text {
        font-size: 12px;
        font-weight: 800;
        text-align: center;
        color: #ffffff;
        margin-top: 30px;
        position: relative;
        top: -46px;
    }
    .frame-textt {
        font-weight: 400;
        font-size: 11px;
        text-align: center;
        color: #ffffff;
        margin-top: -17px;
        position: relative;
        top: -46px;
    }

}